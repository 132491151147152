


























import { Component, Vue } from 'vue-property-decorator';
import SectionCircleLink from '../../../components/SectionCircleLink.vue';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '../../../stores/player.store';
import { SsrCtx, WithAsyncData, WithMeta } from '@/core/vue.types';
import { universalRedirect } from '../../../fns/router';
import { TicketCreatorService } from '@/modules/sts/creator/tickets-creator.service';

import {
  CreatingTicketParameterOption,
  TicketCreatorOptions,
} from '@/modules/sts/creator/creator-options.model';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { TicketsStore } from '@/themes/v1/stores/tickets.store';
import CircleSectionGroup from '@/themes/v1/components/CircleSectionGroup.vue';
import { makeGroups } from '@/themes/v1/fns/array-utils';
import { MetaInfo } from 'vue-meta';
import TestLocator from '@/core/test-utils/test-locator';
import Multiline from '@/themes/v1/directives/multiline-text';

@Component({
  components: { CircleSectionGroup, SectionCircleLink },
  directives: { TestLocator, Multiline },
})
export default class GamePlatform
  extends Vue
  implements WithAsyncData, WithMeta {
  platforms: CreatingTicketParameterOption[] = [];

  async asyncData(ctx: SsrCtx) {
    const playerStore = getModule(PlayerStore, ctx.store);
    
    if (
      (playerStore.isAuth && playerStore.platformType) ||
      (playerStore.wasAuthorized && playerStore.platformType)
    ) {
      universalRedirect(ctx, `problems/${playerStore.platformType}`);
      return {};
    }

    const lang = ctx.route.params.lang;
    const ticketsStore = getModule(TicketsStore, ctx.store);
    let creationOptions: TicketCreatorOptions;
    if (ticketsStore.language == lang) {
      creationOptions = ticketsStore.options as TicketCreatorOptions;
    } else {
      const createrService = resolveFromCtx<TicketCreatorService>(
        'ticketCreatorService',
        ctx
      );
      creationOptions = await createrService.getOptions(lang);
      ticketsStore.setOptions({ lang, options: creationOptions });
    }

    return {
      platforms: creationOptions.platforms,
    };
  }

  get groups() {
    return makeGroups<CreatingTicketParameterOption>(this.platforms, 2);
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('meta.createTicket.title') as string,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('meta.createTicket.description') as string,
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.$t('meta.createTicket.keywords') as string,
        },
      ],
    };
  }
}
